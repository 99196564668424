<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">测评列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="测评问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em">测评问卷名称:</span>
              <el-input v-model="paperName" type="text" clearable size="small" placeholder="请输入测评问卷名称" />
            </div>
            <div title="发布状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em">发布状态:</span>
              <el-select size="small" clearable v-model="published" placeholder="请选择">
                <el-option :key="true" label="已发布" :value="true"></el-option>
                <el-option :key="false" label="未发布" :value="false"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="add()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" stripe :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="测评问卷名称" align="left" show-overflow-tooltip prop="paperName" />
              <el-table-column label="分类" align="center" show-overflow-tooltip prop="evaluateCategory">
                <template slot-scope="scope">{{
                  $setDictionary("HR_EVALUATE_CATEGORY", scope.row.evaluateCategory)
                }}
                </template>
              </el-table-column>
              <el-table-column label="来源" align="left" show-overflow-tooltip prop="paperSource" />

              <el-table-column label="测评人次" align="left" show-overflow-tooltip prop="peopleNum" />
              <el-table-column label="发布状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.published == true ? '已发布' : '未发布' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="300px">
                <div slot-scope="scope" class="flexcc">
                  <el-button style="padding: 0px 5px" type="text" size="mini" @click="onEditor(scope.row)">{{
                    scope.row.canModify ? '编辑' : '查看' }}</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini" @click="onPublishRecycle(scope.row)">{{
                    scope.row.published ? '回收' : '发布' }}</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini"
                    @click="onSetPage('1', scope.row)">题目设置</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini"
                    @click="onSetPage('2', scope.row)">结果设置</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini"
                    @click="setPrice(scope.row)">价格设置</el-button>
                  <el-button style="padding: 0px 5px" type="text" size="mini" @click="onDelete(scope.row)"
                    :disabled="!scope.row.canDelete">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增/编辑  弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="80%" center class="relation_jg_dialog"
      :close-on-click-modal="false" @close="doClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="8rem" class="demo-ruleForm"
        style="width: 90%">
        <el-form-item label="测评问卷标题：" prop="paperName">
          <el-input v-model="ruleForm.paperName" size="small" placeholder="请输入测评问卷标题" maxlength="50"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="副标题：" prop="evaluateTitle">
          <el-input v-model="ruleForm.evaluateTitle" size="small" placeholder="请输入副标题" maxlength="50"
            show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类：" prop="evaluateCategory">
          <el-select v-model="ruleForm.evaluateCategory" placeholder="请选择分类" size="small" clearable>
            <el-option v-for="item in evaluateCategoryList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传封面" prop="paperImgUrl" :class="[rotationId ? 'item-disabled' : '']" required>
          <el-upload :on-change="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2" :http-request="$requestMine"
            :show-file-list="false" class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
            <el-image :src="ruleForm.paperImgUrl || require('@/assets/develop.png')" fit="contain"
              class="imgCenter"></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="测评详情：" prop="description" class="form-item" style="overflow: hidden;position:relative;">
          <span style="color: red;position:absolute;top: 0;left: -94px">*</span>
          <div ref="editor" class="editor" style="width:100%;max-width:1500px"></div>
          <span v-show="descriptionTitle" class="showtitle">请输入测评详情</span>
        </el-form-item>
        <el-form-item label="问卷来源：" prop="paperSource">
          <el-input v-model="ruleForm.paperSource" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" size="small"
            placeholder="请输入问卷来源" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center" v-if="canModify">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')">
          确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 价格设置  弹窗 -->
    <el-dialog title="售卖价格设置" :visible.sync="setPriceData.dialogVisible" width="500px" :before-close="setPriceClose">
      <el-form :model="setPriceData" :rules="setPriceDataRules" ref="setPriceData" label-width="100px" size="small"
        class="demo-ruleForm">
        <el-form-item label="是否收费" prop="free">
          <el-radio-group v-model="setPriceData.free" @change="setFree">
            <el-radio :label="true">免费</el-radio>
            <el-radio :label="false">收费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收费模式" prop="chargeMode">
          <el-radio-group v-model="setPriceData.chargeMode" :disabled="setPriceData.free">
            <el-radio :label="item.value" v-for="(item, index) in chargingModeData" :key="index">{{ item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="设置售卖价格"></el-form-item>
        <el-form-item label="划线价格">
          <el-input style="width:100px;" v-model="setPriceData.markingPrice"
            @keyup.native="setPriceData.markingPrice = zF.oninput3(setPriceData.markingPrice, 2)"
            placeholder="请输入价格"></el-input>
          <span style="margin-left: 5px;">元（选填）</span>
          <span style="margin-left: 20px;text-decoration: line-through;">示例：{{ setPriceData.markingPrice }} 元</span>
        </el-form-item>
        <el-form-item label="售卖价格" prop="sellingPrice">
          <el-input style="width:100px;" v-model="setPriceData.sellingPrice"
            @keyup.native="setPriceData.sellingPrice = zF.oninput3(setPriceData.sellingPrice, 2)"
            placeholder="请输入价格" :disabled="setPriceData.free"></el-input>
          <span style="margin-left: 5px;">元</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="setPriceClose">取 消</el-button>
        <el-button size="small" class="bgc-bv" @click="setPriceSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";
import { resetKeepAlive, validateURL } from "@/utils/common";
let editor = {};
export default {
  name: "evaluationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List, UploadPic],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      //问卷名称
      paperName: "",
      //发布状态
      published: "",
      // 操作列 - 列表：编辑/查看
      canModify: true,
      // 新增/编辑 - 弹框状态
      centerDialogVisible: false,
      // 新增/编辑 - 弹框的标题
      dialogTitle: "",
      // 新增/编辑 - 数据
      ruleForm: {
        paperId: "", // 测评问卷ID
        paperName: "", // 测评问卷名称
        evaluateTitle: "", // 副标题
        evaluateCategory: "20", // 分类
        paperPurpose: "10", // 调查目标: [ HR_SURVEY_PURPOSE ] 10-心理测评; 20-调查评估
        paperImgUrl: "", // 封面
        paperImg: "", // 封面key
        description: '',// 测评详情
        paperSource: "", // 问卷来源
      },
      // 新增/编辑 - 测评详情提示文字的显示与否
      descriptionTitle: false,
      // 新增/编辑 - 分类标识字典数据
      evaluateCategoryList: [],
      // 新增/编辑 - 数据校验
      rules: {
        paperName: [{ required: true, message: "请输入测评问卷标题", trigger: "blur" }],
        evaluateTitle: [{ required: true, message: "请输入副标题", trigger: "blur" }],
        evaluateCategory: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        paperImgUrl: [
          { required: true, message: "请上传封面", trigger: "change" }
        ],
        paperSource: [{ required: true, message: "请输入问卷来源", trigger: "blur" }],
      },
      // 价格设置弹框数据
      setPriceData: {
        dialogVisible: false, // 弹框状态
        paperId: "", // 调查问卷id
        free: false, // 是否收费
        chargeMode: "10", // 收费模式
        markingPrice: "", // 划线价格
        sellingPrice: 0, // 售卖价格
      },
      // 收费模式字典
      chargingModeData: [],
      // 价格设置弹框数据校验
      setPriceDataRules: {
        free: [
          {
            required: true,
            message: "请选择是否收费",
            trigger: "change",
          },
        ],
        chargeMode: [
          {
            required: true,
            message: "请选择收费模式",
            trigger: "change",
          },
        ],
        sellingPrice: [
          {
            required: true,
            message: "请输入售卖价格",
            trigger: "blur",
          },
        ],
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getDictionary();
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    // 获取字典
    getDictionary() {
      // 测评分类
      let EvaluateCategory = this.$setDictionary("HR_EVALUATE_CATEGORY", "list");
      for (const key in EvaluateCategory) {
        this.evaluateCategoryList.push({
          value: key,
          label: EvaluateCategory[key],
        });
      }
      // 收费模式
      let arr1 = this.$setDictionary("HR_EVALUATE_CHARGE_MODE", "list");
      for (const key in arr1) {
        this.chargingModeData.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.ruleForm.paperImgUrl = result.data.fileURL || "";
            this.ruleForm.paperImg = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },
    // 富文本
    editorInit(description) {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
      let _this = this;
      editor.config.customUploadImg = function (files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "COURSE_WARE");
          formData.append(
            "fileType ",
            files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then(result => {
            insert(result.data.fileURL);
          });
        }
      };
      editor.config.onchange = html => {
        this.ruleForm.description = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      if (description) {
        editor.txt.html(description);
        this.ruleForm.description = description

      }

      // const eWidth = this.$refs.editor.clientWidth;
      // this.eWidth = eWidth;
    },
    // 新增
    add() {
      this.centerDialogVisible = true;
      this.dialogTitle = "新增测评问卷";
      this.$nextTick(() => {
        this.editorInit();
      })
    },
    // 修改
    onEditor(row) {
      this.$post("/biz/hr/evaluate/paper/getInfo", {
        paperId: row.paperId,
      }, 3000, true, 6).then((ret) => {
        if (ret.status == 0) {
          this.ruleForm = {
            ...ret.data
          }
          this.centerDialogVisible = true;
          this.canModify = row.canModify
          if (row.canModify) {
            this.dialogTitle = "编辑测评问卷";
          } else {
            this.dialogTitle = "查看测评问卷";
          }

          this.$nextTick(() => {
            this.editorInit(ret.data.description);

          })
        }
      });
      // this.ruleForm = {
      //   paperId: row.paperId, // 销售员id
      //   paperName: row.paperName, // 测评问卷名称
      //   evaluateTitle: row.evaluateTitle, // 副标题
      //   evaluateCategory: row.evaluateCategory, // 人员类型
      //   paperImgUrl: row.paperImgUrl, // 问卷展示图片
      //   paperSource: row.paperSource, //试卷来源描述
      // };
      // this.centerDialogVisible = true;
      // this.dialogTitle = "编辑测评问卷";
      // this.$nextTick(()=>{
      //   this.editorInit(row.description);
      //
      // })
    },
    // 发布回收
    onPublishRecycle(row) {
      this.$post("/biz/hr/evaluate/paper/publish", {
        paperId: row.paperId,
      }, 3000, true, 6).then((ret) => {
        this.$message({
          type: "success",
          message: row.published ? '回收成功' : '发布成功!',
        });
        this.getData(-1);
      });
    },
    // 删除
    onDelete(row) {
      this.$confirm("此操作将永久删除该测评问卷, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/hr/evaluate/paper/delete", {
            paperId: row.paperId,
          }, 3000, true, 6).then((ret) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData(-1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.paperName) {
        params.paperName = this.paperName
      }
      if (this.published === true || this.published === false) {
        params.published = this.published
      }
      this.doFetch({
        url: "/biz/hr/evaluate/paper/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 新增、编辑 - 关闭
    doClose() {
      this.centerDialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.canModify = true;
      this.ruleForm = this.$options.data().ruleForm;
      this.descriptionTitle = false;
      editor.destroy();
    },
    // 新增、编辑 - 确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let str = this.ruleForm.description.replace(/<[^>]+>/g, "");
          str.replace(/\s*/g, "");
          if (!str) {
            this.descriptionTitle = true
            return false;
          } else {
            this.descriptionTitle = false
            if (editor.txt.html().length > 2000) {
              this.$message({
                type: "error",
                message: "测评详情不允许超过2000字符！"
              });
              return
            } else {

              this.doAjax();
            }
          }
        }
      });
    },
    // 新增、编辑 - 确认 - 接口调用
    doAjax() {
      let url = "/biz/hr/evaluate/paper/insert";
      let params = {
        paperName: this.ruleForm.paperName,
        evaluateTitle: this.ruleForm.evaluateTitle,
        evaluateCategory: this.ruleForm.evaluateCategory,
        paperImgUrl: this.ruleForm.paperImgUrl,
        paperImg: this.ruleForm.paperImg,
        description: this.ruleForm.description,
        paperSource: this.ruleForm.paperSource,
        paperPurpose: '10' // 暂时写死
      };
      if (this.ruleForm.paperId) {
        params.paperId = this.ruleForm.paperId;
        url = "/biz/hr/evaluate/paper/modify";
      }
      this.$post(url, params, 3000, true, 6)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 点击设置按钮
    onSetPage(stu, row = {}) {
      row.ctype = 'yw'
      // stu  1 题目设置   2 结果设置
      if (stu == '1') {
        this.$router.push({
          path: "/web/miniApp/evaluation/itemBankSetting",
          query: {
            row
          },
        });
      } else if (stu == '2') {
        this.$router.push({
          path: "/web/miniApp/evaluation/structureSectting",
          query: {
            row
          },
        });
      }
    },
    // 价格设置
    setPrice(row) {
      this.setPriceData.paperId = row.paperId;
      this.$post('/biz/hr/evaluate/paper/price/get', { paperId: row.paperId }, 3000, true, 6)
        .then((res) => {
          this.setPriceData = {
            ...this.setPriceData,
            ...res.data
          }
        })
        .catch((err) => {
          return;
        });
      this.setPriceData.dialogVisible = true;
      // 
    },
    // 点击是否收费
    setFree(val){
      if(val) {
        this.setPriceData.sellingPrice = 0;
        this.setPriceData.chargeMode = '10';
      }
    },
    // 价格设置 - 提交
    setPriceSubmit() {
      this.$refs['setPriceData'].validate((valid) => {
        if (valid) {
          this.$post('/biz/hr/evaluate/paper/price/setting', this.setPriceData, 3000, true, 6)
            .then((res) => {
              console.log(res)
              if (res.status == "0") {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.setPriceClose();
                this.getData(this.pageNum);
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 价格设置 - 关闭
    setPriceClose() {
      this.setPriceData = this.$options.data().setPriceData;
      this.$refs["setPriceData"].clearValidate();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}

/deep/.el-textarea .el-input__count {
  line-height: 20px;
}
</style>
